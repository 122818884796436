import { ApiHttpService } from "../api-http.service";
import * as i0 from "@angular/core";
import * as i1 from "../api-http.service";
var OrganizationService = /** @class */ (function () {
    function OrganizationService(http) {
        this.http = http;
        this.e = '/organizations';
    }
    OrganizationService.prototype.list = function (qp) {
        return this.http.get(this.e, qp);
    };
    OrganizationService.prototype.save = function (data, qp) {
        return this.http.save(this.e + '/{id}', data, qp);
    };
    OrganizationService.prototype.get = function (id, qp) {
        return this.http.get(this.e + "/" + id, qp);
    };
    OrganizationService.ngInjectableDef = i0.defineInjectable({ factory: function OrganizationService_Factory() { return new OrganizationService(i0.inject(i1.ApiHttpService)); }, token: OrganizationService, providedIn: "root" });
    return OrganizationService;
}());
export { OrganizationService };
