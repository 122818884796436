<header [ngClass]="{masquerade: user.isMasquerading}" class="topbar-light" id="topnav">
    <nav class="navbar-custom">
        <ul class="list-unstyled topbar-right-menu float-right mb-0">
            <li class="dropdown notification-list" dropdown>
                <a aria-expanded="false" aria-haspopup="false"
                   class="nav-link dropdown-toggle nav-user"
                   dropdownToggle
                   href="javascript:void(0);"
                   role="button">
                    <!--<img src="https://placeimg.com/640/480/people"
                         alt="user"
                         class="rounded-circle">-->
                    <i class="dripicons-bell noti-icon h4" style="vertical-align: sub"></i>
                    <span *ngIf="header.notificationCount > 0"
                          class="badge badge-info noti-icon-badge">
                        {{header.notificationCount}}
                    </span>
                </a>
                <div *dropdownMenu
                     class="dropdown-menu dropdown-menu-right dropdown-menu-animated dropdown-lg">
                    <div class="dropdown-item noti-title">
                        <h5 class="m-0">
                            <span class="float-right">
                                <a (click)="clearNotification()"
                                   class="text-dark"
                                   href="javascript:;"><small>Clear All</small></a>
                            </span>
                            Notification
                        </h5>
                    </div>

                    <div>
                        <a *ngFor="let item of header.notifications"
                           class="dropdown-item notify-item">
                            <div [ngClass]="{'bg-danger': item.extra.status=='stressed',
                                             'bg-info': item.extra.status=='ok',
                                             'bg-warning': item.extra.status=='flooded',
                                             'bg-dark': item.extra.status=='offline' ||
                                                        item.extra.status=='low_sply_vol' ||
                                                        item.extra.status=='low_ul_volt'}"
                                 class="notify-icon">
                                <i [ngClass]="{'mdi-alert-circle-outline': item.extra.status=='stressed',
                                             'mdi-check-circle-outline': item.extra.status=='ok',
                                             'mdi-information-outline': item.extra.status=='flooded',
                                             'mdi-power-plug-off': item.extra.status=='offline',
                                             'mdi-flash-circle': item.extra.status=='low_ul_volt' ||
                                                                 item.extra.status=='low_sply_vol'}"
                                   class="mdi mdi-24px"></i>
                            </div>
                            <p class="notify-details">
                                Probe {{item.extra.probe_name}} is {{item.extra.status}}
                                <small class="text-muted">
                                    {{item.created_at|date:'short'}}
                                </small>
                            </p>
                        </a>

                        <p *ngIf="header.notificationCount == 0 && !ui.isNotificationLoading"
                           class="text-center text-muted small">
                            No new notification
                        </p>
                    </div>

                    <a class="dropdown-item text-center text-primary notify-item notify-all"
                       [routerLink]="user.isAdminOrStaff ? '/a/notifications':'/u/notifications'">
                        View all <i class="fi-arrow-right"></i>
                    </a>
                </div>
            </li>

            <li class="dropdown notification-list" dropdown>
                <a aria-expanded="false" aria-haspopup="false"
                   class="nav-link dropdown-toggle nav-user"
                   dropdownToggle
                   href="javascript:void(0);"
                   role="button">
                    <!--<img src="https://placeimg.com/640/480/people"
                         alt="user"
                         class="rounded-circle">-->
                    <i class="dripicons-user h4" style="vertical-align: sub"></i>
                    <span class="ml-1">
                        {{(user.detail|async)?.first_name}}
                        {{user.isMasquerading ? '[impersonated]' : ''}}
                        <i class="mdi mdi-chevron-down"></i>
                    </span>
                </a>
                <div *dropdownMenu
                     class="dropdown-menu dropdown-menu-right dropdown-menu-animated profile-dropdown">
                    <!-- item-->
                    <div class="dropdown-item noti-title">
                        <h6 class="text-overflow m-0">Welcome !</h6>
                    </div>

                    <a *ngIf="(route.snapshot.url|get:'0.path')=='admin'"
                       class="dropdown-item notify-item"
                       routerLink="/">
                        <i class="dripicons-home"></i> <span>Home</span>
                    </a>

                    <a [routerLink]="user.isAdminOrStaff ? '/a/users/'+user.detail.value.id+'/edit':'/u/profile'"
                       class="dropdown-item notify-item">
                        <i class="dripicons-user-id"></i> <span> Profile </span>
                    </a>

                    <div class="divider dropdown-divider"></div>

                    <!-- item-->
                    <a (click)="exitMasquerade()" *ngIf="user.isMasquerading"
                       class="dropdown-item notify-item c-pointer">
                        <i class="dripicons-exit"></i> <span>Exit masquerade</span>
                    </a>
                    <a (click)="logout()" class="dropdown-item notify-item c-pointer">
                        <i class="dripicons-power"></i> <span>Logout</span>
                    </a>
                </div>
            </li>
        </ul>

        <ul class="list-unstyled menu-left mb-0">
            <li class="float-left">
                <a [title]="startupSrv.data.name" class="logo logo-light"
                   routerLink="/">
                    <span class="logo-lg">
                        <img [src]="startupSrv.data.logo.url" alt="" height="50px">
                    </span>
                </a>
            </li>
            <li class="float-left">
                <a (click)="leftMenu.toggle()"
                   [ngClass]="{open: leftMenu.isSmallWindow ?
                                        leftMenu.isVisible:!leftMenu.isVisible}"
                   class="button-menu-mobile open-left navbar-toggle">
                    <div class="lines">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </a>
            </li>

            <li class="app-search">
                <h3 class="text-secondary m-0" style="line-height: 1.7em">
                    Deep Rooted Intelligence
                </h3>
            </li>
        </ul>
    </nav>
    <!-- end navbar-custom -->
</header>

