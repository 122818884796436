import { FormControl, FormGroup } from "@angular/forms";
import * as _ from 'lodash';
var Utils = /** @class */ (function () {
    function Utils() {
    }
    Utils.validateAllFormFields = function (form) {
        var _this = this;
        Object.keys(form.controls).forEach(function (field) {
            var control = form.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched({ onlySelf: true });
            }
            else if (control instanceof FormGroup) {
                _this.validateAllFormFields(control);
            }
        });
    };
    /**
     * Replace template element in a string. Template element can be written between curly braces.
     * Template element will be looked into the object for replacement
     *
     * @param {string} string String to replaced
     * @param {object} obj any object where template string value should be picked up
     * @param {boolean} forceReplace if value is missing this will tell it to replace with blank str
     * @returns {string} Replaced string
     */
    Utils.format = function (string, obj, forceReplace) {
        if (forceReplace === void 0) { forceReplace = true; }
        var params = string.match(/{[^}.]+}/g);
        if (params === null)
            return string;
        params.forEach(function (str) {
            var replacement = _.get(obj, str.replace(/[{}]/g, '')) || null;
            if (replacement === null && forceReplace == true)
                replacement = '';
            else if (replacement === null)
                replacement = str;
            string = string.replace(str, replacement);
        });
        return string;
    };
    Utils.isSmallWindow = function () {
        return window.innerWidth < 1025;
    };
    Utils.slugify = function (input) {
        return input.toString().toLowerCase()
            .replace(/\s+/g, '-') // Replace spaces with -
            .replace(/[^\w\-]+/g, '') // Remove all non-word chars
            .replace(/\-\-+/g, '-') // Replace multiple - with single -
            .replace(/^-+/, '') // Trim - from start of text
            .replace(/-+$/, ''); // Trim - from end of text
    };
    return Utils;
}());
export default Utils;
