<div class="left-side-menu left-side-menu-dark">
    <perfect-scrollbar>
        <div class="slimscroll-menu">
            <div id="sidebar-menu">
                <ul #metismenu class="metismenu" id="side-menu">
                    <ng-template [ngForOf]="sidebarSrv.items|async" let-i="index" let-section ngFor>
                        <ng-container *ngIf="section.isVisible!=false">
                            <li class="menu-title">{{section.title}}</li>

                            <li #l *ngFor="let item of section.items"
                                [ngClass]="{active: l.querySelectorAll('.nav-second-level a.active').length > 0}">

                                <ng-container *ngIf="item.isVisible!=false">

                                    <!-- When an item doesn't have children -->
                                    <a *ngIf="item.route"
                                       [queryParams]="item.queryParams"
                                       [routerLink]="[item.route]"
                                       routerLinkActive="active">

                                        <i *ngIf="item.icon" [class]="item.icon"></i> {{item.title}}
                                    </a>


                                    <!-- When an item has children -->
                                    <ng-container *ngIf="item.children && item.children.length > 0">
                                        <a [ngClass]="{active: l.querySelectorAll('.nav-second-level  a.active').length > 0}"
                                           href="javascript:void(0);">
                                            <img *ngIf="item.imgSrc" [src]="item.imgSrc"
                                                 width="50px">
                                            <i *ngIf="item.icon" [class]="item.icon"></i>
                                            <span> {{item.title}} </span>
                                            <span class="menu-arrow"></span>
                                        </a>

                                        <ul *ngIf="item.children && item.children.length > 0"
                                            [ngClass]="{in: l.querySelectorAll('.nav-second-level  a.active').length > 0}"
                                            class="nav-second-level nav">

                                            <li *ngFor="let child of item.children"
                                                [routerLinkActiveOptions]="{exact: true}"
                                                routerLinkActive="active">
                                                <a *ngIf="child.route"
                                                   [queryParams]="child.queryParams"
                                                   [routerLink]="[child.route]"
                                                   [queryParamsHandling]="child.queryParamsHandling"
                                                   routerLinkActive="active">
                                                    {{child.title}}
                                                </a>

                                                <a *ngIf="child.children && child.children.length>0"
                                                   class="has-arrow"
                                                   href="javascript: void(0);"
                                                   routerLinkActive="active">
                                                    {{child.title}}
                                                </a>

                                                <!--<ul class="nav-third-level nav"
                                                    routerLinkActive="in">
                                                    <li *ngFor="let subChild of child.children"
                                                        routerLinkActive="active">
                                                        <a *ngIf="subChild.route"
                                                           [queryParams]="subChild.queryParams"
                                                           [routerLink]="[subChild.route]"
                                                           routerLinkActive="active">
                                                            {{subChild.title}}
                                                        </a>

                                                        <a *ngIf="!subChild.route"
                                                           href="javascript:void(0);">
                                                            {{subChild.title}}
                                                        </a>
                                                    </li>
                                                </ul>-->
                                            </li>
                                        </ul>
                                    </ng-container>
                                </ng-container>
                            </li>
                        </ng-container>
                    </ng-template>
                </ul>
            </div>
        </div>
    </perfect-scrollbar>
</div>
