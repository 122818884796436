import { ApiHttpService } from "./api-http.service";
import { AuthService } from "./auth/auth.service";
import { CloudFilesService } from "./cloudfiles/cloud-files.service";
import { FTPProcessLogService } from "./ftp-process-log/ftp-process-log.service";
import { GroupService } from "./group/group.service";
import { NotificationService } from "./notification/notification.service";
import { OrganizationService } from "./organization/organization.service";
import { PermissionService } from "./permission/permission.service";
import { ProbeService } from "./probe/probe.service";
import { ReportService } from "./report/report.service";
import { SensorsLogCommentService } from "./sensors-log-comment/sensors-log-comment.service";
import { SystemService } from "./system/system.service";
import { UserService } from "./user/user.service";
import * as i0 from "@angular/core";
import * as i1 from "./api-http.service";
import * as i2 from "./auth/auth.service";
import * as i3 from "./system/system.service";
import * as i4 from "./organization/organization.service";
import * as i5 from "./user/user.service";
import * as i6 from "./group/group.service";
import * as i7 from "./permission/permission.service";
import * as i8 from "./probe/probe.service";
import * as i9 from "./sensors-log-comment/sensors-log-comment.service";
import * as i10 from "./notification/notification.service";
import * as i11 from "./report/report.service";
import * as i12 from "./ftp-process-log/ftp-process-log.service";
import * as i13 from "./cloudfiles/cloud-files.service";
var ApiSdkService = /** @class */ (function () {
    function ApiSdkService(http, auth, system, organization, user, group, permission, probe, sensorsLogComment, notification, report, ftpProcessLog, cloudfile) {
        this.http = http;
        this.auth = auth;
        this.system = system;
        this.organization = organization;
        this.user = user;
        this.group = group;
        this.permission = permission;
        this.probe = probe;
        this.sensorsLogComment = sensorsLogComment;
        this.notification = notification;
        this.report = report;
        this.ftpProcessLog = ftpProcessLog;
        this.cloudfile = cloudfile;
    }
    ApiSdkService.ngInjectableDef = i0.defineInjectable({ factory: function ApiSdkService_Factory() { return new ApiSdkService(i0.inject(i1.ApiHttpService), i0.inject(i2.AuthService), i0.inject(i3.SystemService), i0.inject(i4.OrganizationService), i0.inject(i5.UserService), i0.inject(i6.GroupService), i0.inject(i7.PermissionService), i0.inject(i8.ProbeService), i0.inject(i9.SensorsLogCommentService), i0.inject(i10.NotificationService), i0.inject(i11.ReportService), i0.inject(i12.FTPProcessLogService), i0.inject(i13.CloudFilesService)); }, token: ApiSdkService, providedIn: "root" });
    return ApiSdkService;
}());
export { ApiSdkService };
