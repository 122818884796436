import { ApiHttpService } from "../api-http.service";
import * as i0 from "@angular/core";
import * as i1 from "../api-http.service";
var UserService = /** @class */ (function () {
    function UserService(http) {
        this.http = http;
        this.e = '/users';
    }
    UserService.prototype.save = function (data) {
        return this.http.save(this.e + '/{id}', data);
    };
    UserService.prototype.list = function (qp) {
        return this.http.get(this.e, qp);
    };
    UserService.prototype.changePassword = function (userId, data) {
        return this.http.put("/users/" + userId + "/actions/change_password", data);
    };
    UserService.prototype.resetPasswordRequest = function (email) {
        return this.http.post(this.e + "/actions/password_reset", { email: email });
    };
    UserService.prototype.resetPassword = function (data) {
        return this.http.put(this.e + "/actions/password_reset", data);
    };
    UserService.prototype.get = function (id) {
        return this.http.get(this.e + "/" + id);
    };
    UserService.prototype.remove = function (id) {
        return this.http.remove(this.e + "/" + id);
    };
    UserService.ngInjectableDef = i0.defineInjectable({ factory: function UserService_Factory() { return new UserService(i0.inject(i1.ApiHttpService)); }, token: UserService, providedIn: "root" });
    return UserService;
}());
export { UserService };
