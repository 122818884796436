import { ViewContainerRef } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { filter, tap } from "rxjs/operators";
var ContentHeaderService = /** @class */ (function () {
    function ContentHeaderService(router) {
        this.router = router;
        this._pageTitle = '';
        this._isPageTitleHidden = false;
        this._clearPageAction = true;
        this._breadcrumb = [];
        this.clearActionContainer();
    }
    Object.defineProperty(ContentHeaderService.prototype, "pageTitle", {
        get: function () {
            return this._pageTitle;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ContentHeaderService.prototype, "breadcrumb", {
        get: function () {
            var items = [];
            if (this.breadcrumbHome)
                items.push(this.breadcrumbHome);
            return items.concat(this._breadcrumb);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ContentHeaderService.prototype, "isPageTitleHidden", {
        get: function () {
            return this._isPageTitleHidden;
        },
        set: function (value) {
            var _this = this;
            setTimeout(function () { return _this._isPageTitleHidden = value; });
        },
        enumerable: true,
        configurable: true
    });
    ContentHeaderService.prototype.setPageTitle = function (title) {
        var _this = this;
        setTimeout(function () { return _this._pageTitle = title; });
    };
    ContentHeaderService.prototype.setBreadcrumb = function (items) {
        var _this = this;
        setTimeout(function () { return _this._breadcrumb = items; });
    };
    ContentHeaderService.prototype.setBreadcrumbHome = function (item) {
        this.breadcrumbHome = item;
    };
    ContentHeaderService.prototype.clearPageActionOnRouteChange = function (v) {
        this._clearPageAction = v;
    };
    Object.defineProperty(ContentHeaderService.prototype, "pageActionContainer", {
        set: function (container) {
            this._actionContainer = container;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ContentHeaderService.prototype, "pageAction", {
        set: function (tpl) {
            var _this = this;
            setTimeout(function () {
                _this._actionContainer.clear();
                _this._actionContainer.createEmbeddedView(tpl);
            });
        },
        enumerable: true,
        configurable: true
    });
    ContentHeaderService.prototype.clearActionContainer = function () {
        var _this = this;
        this.router.events
            .pipe(filter(function (event) { return event instanceof NavigationEnd && _this._clearPageAction; }))
            .pipe(tap(function () {
            try {
                _this._actionContainer.clear();
            }
            catch (e) {
            }
        }))
            .subscribe();
    };
    return ContentHeaderService;
}());
export { ContentHeaderService };
