/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./left-menu.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "../../../../../node_modules/ngx-perfect-scrollbar/dist/ngx-perfect-scrollbar.ngfactory";
import * as i5 from "ngx-perfect-scrollbar";
import * as i6 from "./left-menu.component";
import * as i7 from "./left-menu.service";
var styles_LeftMenuComponent = [i0.styles];
var RenderType_LeftMenuComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_LeftMenuComponent, data: {} });
export { RenderType_LeftMenuComponent as RenderType_LeftMenuComponent };
function View_LeftMenuComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.parent.parent.parent.context.$implicit.icon; _ck(_v, 0, 0, currVal_0); }); }
function View_LeftMenuComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "a", [["routerLinkActive", "active"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, [[3, 4]], 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { queryParams: [0, "queryParams"], routerLink: [1, "routerLink"] }, null), i1.ɵpad(2, 1), i1.ɵdid(3, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 2, { links: 1 }), i1.ɵqud(603979776, 3, { linksWithHrefs: 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LeftMenuComponent_6)), i1.ɵdid(7, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(8, null, [" ", " "]))], function (_ck, _v) { var currVal_2 = _v.parent.parent.context.$implicit.queryParams; var currVal_3 = _ck(_v, 2, 0, _v.parent.parent.context.$implicit.route); _ck(_v, 1, 0, currVal_2, currVal_3); var currVal_4 = "active"; _ck(_v, 3, 0, currVal_4); var currVal_5 = _v.parent.parent.context.$implicit.icon; _ck(_v, 7, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_6 = _v.parent.parent.context.$implicit.title; _ck(_v, 8, 0, currVal_6); }); }
function View_LeftMenuComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["width", "50px"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.parent.parent.parent.context.$implicit.imgSrc; _ck(_v, 0, 0, currVal_0); }); }
function View_LeftMenuComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.parent.parent.parent.context.$implicit.icon; _ck(_v, 0, 0, currVal_0); }); }
function View_LeftMenuComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "a", [["routerLinkActive", "active"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, [[7, 4], [5, 4]], 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { queryParams: [0, "queryParams"], queryParamsHandling: [1, "queryParamsHandling"], routerLink: [2, "routerLink"] }, null), i1.ɵpad(2, 1), i1.ɵdid(3, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 6, { links: 1 }), i1.ɵqud(603979776, 7, { linksWithHrefs: 1 }), (_l()(), i1.ɵted(6, null, [" ", " "]))], function (_ck, _v) { var currVal_2 = _v.parent.context.$implicit.queryParams; var currVal_3 = _v.parent.context.$implicit.queryParamsHandling; var currVal_4 = _ck(_v, 2, 0, _v.parent.context.$implicit.route); _ck(_v, 1, 0, currVal_2, currVal_3, currVal_4); var currVal_5 = "active"; _ck(_v, 3, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_6 = _v.parent.context.$implicit.title; _ck(_v, 6, 0, currVal_6); }); }
function View_LeftMenuComponent_13(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "a", [["class", "has-arrow"], ["href", "javascript: void(0);"], ["routerLinkActive", "active"]], null, null, null, null, null)), i1.ɵdid(1, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 8, { links: 1 }), i1.ɵqud(603979776, 9, { linksWithHrefs: 1 }), (_l()(), i1.ɵted(4, null, [" ", " "]))], function (_ck, _v) { var currVal_0 = "active"; _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.parent.context.$implicit.title; _ck(_v, 4, 0, currVal_1); }); }
function View_LeftMenuComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "li", [["routerLinkActive", "active"]], null, null, null, null, null)), i1.ɵdid(1, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActiveOptions: [0, "routerLinkActiveOptions"], routerLinkActive: [1, "routerLinkActive"] }, null), i1.ɵqud(603979776, 4, { links: 1 }), i1.ɵqud(603979776, 5, { linksWithHrefs: 1 }), i1.ɵpod(4, { exact: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LeftMenuComponent_12)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LeftMenuComponent_13)), i1.ɵdid(8, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = _ck(_v, 4, 0, true); var currVal_1 = "active"; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _v.context.$implicit.route; _ck(_v, 6, 0, currVal_2); var currVal_3 = (_v.context.$implicit.children && (_v.context.$implicit.children.length > 0)); _ck(_v, 8, 0, currVal_3); }, null); }
function View_LeftMenuComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "ul", [["class", "nav-second-level nav"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i3.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { in: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LeftMenuComponent_11)), i1.ɵdid(4, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = "nav-second-level nav"; var currVal_1 = _ck(_v, 2, 0, (i1.ɵnov(_v.parent.parent.parent, 0).querySelectorAll(".nav-second-level  a.active").length > 0)); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _v.parent.parent.parent.context.$implicit.children; _ck(_v, 4, 0, currVal_2); }, null); }
function View_LeftMenuComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "a", [["href", "javascript:void(0);"]], null, null, null, null, null)), i1.ɵdid(2, 278528, null, 0, i3.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { active: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LeftMenuComponent_8)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LeftMenuComponent_9)), i1.ɵdid(7, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, [" ", " "])), (_l()(), i1.ɵeld(10, 0, null, null, 0, "span", [["class", "menu-arrow"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LeftMenuComponent_10)), i1.ɵdid(12, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _ck(_v, 3, 0, (i1.ɵnov(_v.parent.parent, 0).querySelectorAll(".nav-second-level  a.active").length > 0)); _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.parent.parent.context.$implicit.imgSrc; _ck(_v, 5, 0, currVal_1); var currVal_2 = _v.parent.parent.context.$implicit.icon; _ck(_v, 7, 0, currVal_2); var currVal_4 = (_v.parent.parent.context.$implicit.children && (_v.parent.parent.context.$implicit.children.length > 0)); _ck(_v, 12, 0, currVal_4); }, function (_ck, _v) { var currVal_3 = _v.parent.parent.context.$implicit.title; _ck(_v, 9, 0, currVal_3); }); }
function View_LeftMenuComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LeftMenuComponent_5)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LeftMenuComponent_7)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.route; _ck(_v, 2, 0, currVal_0); var currVal_1 = (_v.parent.context.$implicit.children && (_v.parent.context.$implicit.children.length > 0)); _ck(_v, 4, 0, currVal_1); }, null); }
function View_LeftMenuComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [["l", 1]], null, 4, "li", [], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i3.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(2, { active: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LeftMenuComponent_4)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = _ck(_v, 2, 0, (i1.ɵnov(_v, 0).querySelectorAll(".nav-second-level a.active").length > 0)); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_v.context.$implicit.isVisible != false); _ck(_v, 4, 0, currVal_1); }, null); }
function View_LeftMenuComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "li", [["class", "menu-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LeftMenuComponent_3)), i1.ɵdid(4, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_1 = _v.parent.context.$implicit.items; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.title; _ck(_v, 2, 0, currVal_0); }); }
function View_LeftMenuComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_LeftMenuComponent_2)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = (_v.context.$implicit.isVisible != false); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_LeftMenuComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { metismenu: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "left-side-menu left-side-menu-dark"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "perfect-scrollbar", [], [[2, "ps-show-limits", null], [2, "ps-show-active", null]], null, null, i4.View_PerfectScrollbarComponent_0, i4.RenderType_PerfectScrollbarComponent)), i1.ɵdid(3, 507904, null, 0, i5.PerfectScrollbarComponent, [i1.NgZone, i1.ChangeDetectorRef, i1.PLATFORM_ID], null, null), (_l()(), i1.ɵeld(4, 0, null, 0, 5, "div", [["class", "slimscroll-menu"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["id", "sidebar-menu"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, [[1, 0], ["metismenu", 1]], null, 3, "ul", [["class", "metismenu"], ["id", "side-menu"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_LeftMenuComponent_1)), i1.ɵdid(8, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(131072, i3.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; _ck(_v, 3, 0); var currVal_2 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform(_co.sidebarSrv.items)); _ck(_v, 8, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).autoPropagation; var currVal_1 = i1.ɵnov(_v, 3).scrollIndicators; _ck(_v, 2, 0, currVal_0, currVal_1); }); }
export function View_LeftMenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-left-menu", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onResize() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_LeftMenuComponent_0, RenderType_LeftMenuComponent)), i1.ɵdid(1, 114688, null, 0, i6.LeftMenuComponent, [i7.LeftMenuService, i2.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LeftMenuComponentNgFactory = i1.ɵccf("app-left-menu", i6.LeftMenuComponent, View_LeftMenuComponent_Host_0, {}, {}, []);
export { LeftMenuComponentNgFactory as LeftMenuComponentNgFactory };
