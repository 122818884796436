import { Router } from '@angular/router';
import { ToastrService } from "ngx-toastr";
import { UserService } from "../core/user.service";
import * as i0 from "@angular/core";
import * as i1 from "../core/user.service";
import * as i2 from "@angular/router";
import * as i3 from "ngx-toastr";
var UserGuard = /** @class */ (function () {
    function UserGuard(user, router, t) {
        this.user = user;
        this.router = router;
        this.t = t;
    }
    UserGuard.prototype.canActivate = function (next, state) {
        if (this.user.isAnonymous || this.user.isAdminOrStaff) {
            this.t.error('Page cannot be accessed. Please login with user credential');
            this.router.navigate(['/auth/login'], { queryParams: { next: encodeURIComponent(state.url) } });
            return false;
        }
        return true;
    };
    UserGuard.ngInjectableDef = i0.defineInjectable({ factory: function UserGuard_Factory() { return new UserGuard(i0.inject(i1.UserService), i0.inject(i2.Router), i0.inject(i3.ToastrService)); }, token: UserGuard, providedIn: "root" });
    return UserGuard;
}());
export { UserGuard };
