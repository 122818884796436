import { ApiHttpService } from "../api-http.service";
import * as i0 from "@angular/core";
import * as i1 from "../api-http.service";
var ReportService = /** @class */ (function () {
    function ReportService(http) {
        this.http = http;
        this.e = '/reports';
    }
    ReportService.prototype.downloadEfficiencyReport = function (startDate, endDate, probeIds) {
        var queryParams = {
            start_date: startDate.toISOString(),
            end_date: endDate.toISOString(),
            probe_ids: probeIds.join(',')
        };
        return this.http.download(this.e + "/efficiency_report", 'GET', queryParams);
    };
    ReportService.prototype.downloadProbeSoilReport = function (probeIds) {
        var queryParams = {
            probe_ids: probeIds.join(',')
        };
        return this.http.download(this.e + "/soil_report", 'GET', queryParams);
    };
    ReportService.ngInjectableDef = i0.defineInjectable({ factory: function ReportService_Factory() { return new ReportService(i0.inject(i1.ApiHttpService)); }, token: ReportService, providedIn: "root" });
    return ReportService;
}());
export { ReportService };
