import { EventEmitter } from '@angular/core';
import * as _ from 'lodash';
import { BsModalService } from "ngx-bootstrap";
import { FileUploaderModalComponent } from "./file-uploader-modal.component";
import * as i0 from "@angular/core";
import * as i1 from "ngx-bootstrap/modal";
var FileUploaderService = /** @class */ (function () {
    function FileUploaderService(modalService) {
        this.modalService = modalService;
        this.response = new EventEmitter();
    }
    FileUploaderService.prototype.showModal = function (data) {
        this.bsModalRef = this.modalService.show(FileUploaderModalComponent, {
            initialState: _.merge(data, { response: this.response }),
            class: 'modal-dialog-centered',
            keyboard: false,
            ignoreBackdropClick: true
        });
    };
    FileUploaderService.ngInjectableDef = i0.defineInjectable({ factory: function FileUploaderService_Factory() { return new FileUploaderService(i0.inject(i1.BsModalService)); }, token: FileUploaderService, providedIn: "root" });
    return FileUploaderService;
}());
export { FileUploaderService };
