<!-- Login -->
<section class="bg-account-pages">
    <div class="container">
        <div class="row">
            <div class="col-12">

                <div class="wrapper-page">
                    <div class="account-pages">
                        <div [ngClass]="{'bg-transparent shadow-none': !startupSrv.data}"
                             class="account-box">

                            <!-- Logo box-->
                            <div *ngIf="startupSrv.data" class="account-logo-box">
                                <h2 class="text-uppercase text-center">
                                    <a class="text-success" routerLink="/">
                                        <span>
                                            <img [alt]="startupSrv.data.name"
                                                 [src]="startupSrv.data.logo.url"
                                                 height="50">
                                        </span>
                                    </a>
                                </h2>
                            </div>

                            <div class="account-content">
                                <router-outlet></router-outlet>
                            </div> <!-- end account-content -->

                        </div> <!-- end account-box -->
                    </div>
                    <!-- end account-page-->
                </div>
                <!-- end wrapper-page -->

            </div> <!-- end col -->
        </div> <!-- end row -->
    </div> <!-- end container -->
</section>
<!-- END HOME -->
