import { ApiHttpService } from "../api-http.service";
import * as i0 from "@angular/core";
import * as i1 from "../api-http.service";
var GroupService = /** @class */ (function () {
    function GroupService(http) {
        this.http = http;
        this.e = '/groups';
    }
    GroupService.prototype.list = function (qp) {
        return this.http.get(this.e, qp);
    };
    GroupService.prototype.save = function (data) {
        return this.http.save(this.e + '/{id}', data);
    };
    GroupService.prototype.remove = function (id) {
        return this.http.remove(this.e + ("/" + id));
    };
    GroupService.prototype.get = function (id, qp) {
        return this.http.get(this.e + ("/" + id), qp);
    };
    GroupService.ngInjectableDef = i0.defineInjectable({ factory: function GroupService_Factory() { return new GroupService(i0.inject(i1.ApiHttpService)); }, token: GroupService, providedIn: "root" });
    return GroupService;
}());
export { GroupService };
