import { Router } from '@angular/router';
import { UserService } from "../core/user.service";
import * as i0 from "@angular/core";
import * as i1 from "../core/user.service";
import * as i2 from "@angular/router";
var AuthGuard = /** @class */ (function () {
    function AuthGuard(user, router) {
        this.user = user;
        this.router = router;
    }
    AuthGuard.prototype.canActivate = function (next, state) {
        if (this.user.isAnonymous)
            return true;
        var nextParam = next.queryParamMap.get('next') || '';
        if (nextParam)
            nextParam = '/' + decodeURIComponent(nextParam);
        var routePrefix = '/u';
        if (this.user.isAdminOrStaff)
            routePrefix = '/a';
        return this.router.navigateByUrl("" + routePrefix + nextParam).then(function () { return false; });
    };
    AuthGuard.ngInjectableDef = i0.defineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.inject(i1.UserService), i0.inject(i2.Router)); }, token: AuthGuard, providedIn: "root" });
    return AuthGuard;
}());
export { AuthGuard };
