import { map, shareReplay } from "rxjs/operators";
import { ApiHttpService } from "../api-http.service";
import * as i0 from "@angular/core";
import * as i1 from "../api-http.service";
var SystemService = /** @class */ (function () {
    function SystemService(http) {
        this.http = http;
    }
    Object.defineProperty(SystemService.prototype, "constants", {
        get: function () {
            return this._constantBody;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SystemService.prototype, "_constantBody", {
        get: function () {
            if (!this._constants) {
                this._constants = this.http.get('/system/constants')
                    .pipe(map(function (resp) { return resp.body; }), shareReplay(1));
            }
            return this._constants;
        },
        enumerable: true,
        configurable: true
    });
    SystemService.ngInjectableDef = i0.defineInjectable({ factory: function SystemService_Factory() { return new SystemService(i0.inject(i1.ApiHttpService)); }, token: SystemService, providedIn: "root" });
    return SystemService;
}());
export { SystemService };
