import { ReplaySubject } from "rxjs/internal/ReplaySubject";
import { mergeMap } from "rxjs/operators";
import Utils from "../../../core/utils";
import * as i0 from "@angular/core";
var LeftMenuService = /** @class */ (function () {
    function LeftMenuService() {
        var _this = this;
        this.metismenu = new ReplaySubject(1);
        this.items = new ReplaySubject(1);
        this.items
            .pipe(mergeMap(function () { return _this.metismenu; }))
            .subscribe(function (mm) {
            setTimeout(function () { return mm.update(); }, 1);
        });
    }
    Object.defineProperty(LeftMenuService.prototype, "isVisible", {
        get: function () {
            return !(this.bodyElement.className.indexOf('enlarged') > -1);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LeftMenuService.prototype, "isSmallWindow", {
        get: function () {
            return Utils.isSmallWindow();
        },
        enumerable: true,
        configurable: true
    });
    LeftMenuService.prototype.toggle = function () {
        this.isVisible ? this.hide() : this.show();
    };
    LeftMenuService.prototype.show = function () {
        this.bodyElement.className = this.bodyElement.className.replace('enlarged', '');
    };
    LeftMenuService.prototype.hide = function () {
        if (this.isVisible)
            this.bodyElement.className = ' enlarged';
    };
    Object.defineProperty(LeftMenuService.prototype, "bodyElement", {
        get: function () {
            return document.getElementById('body');
        },
        enumerable: true,
        configurable: true
    });
    LeftMenuService.ngInjectableDef = i0.defineInjectable({ factory: function LeftMenuService_Factory() { return new LeftMenuService(); }, token: LeftMenuService, providedIn: "root" });
    return LeftMenuService;
}());
export { LeftMenuService };
