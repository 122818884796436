<div class="modal-header">
    <h4 class="modal-title pull-left">{{modalTitle}}</h4>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-12">
            <div class="form-group">
                <label>Name</label>
                <input type="text"
                       class="form-control form-control-sm"
                       [(ngModel)]="name">
            </div>

        </div>

        <div class="col-12">
            <input type="file"
                   #fileUploader ngFileSelect
                   [options]="options"
                   [uploadInput]="uploadInput"
                   (uploadOutput)="onUploadOutput($event)"
                   [accept]="accept"
                   class="form-control-file"
                   id="exampleInputFile">

            <small class="help-block">Maximum 10MB of file size allowed.</small>
        </div>

    </div>

    <div class="row" *ngIf="(uploadOutput|async)?.file?.id">
        <div class="col-md-12 pt-2">
            <div>{{(uploadOutput|async)?.file?.name}} ({{(uploadOutput|async)?.file?.id}})</div>

            <progressbar [animate]="true"
                         [value]="(uploadOutput|async)?.file?.progress?.data?.percentage"
                         type="success"
                         style="margin: 5px 0 0 0"
                         class="progress-striped active">
                <b>{{(uploadOutput|async)?.file?.progress?.data?.percentage}}%</b>
            </progressbar>
            <div class="text-right">
                <span>{{(uploadOutput|async)?.file?.progress?.data?.speedHuman}}</span>,
                <span>ETA {{(uploadOutput|async)?.file?.progress?.data?.etaHuman}}</span>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button"
            class="btn btn-secondary btn-sm float-left"
            (click)="bsModalRef.hide()"
            [disabled]="ui.laddaUpload">Close
    </button>
    <button type="submit"
            class="btn btn-primary btn-sm"
            (click)="startUpload()"
            [ladda]="ui.laddaUpload"
            [disabled]="this.files.length==0">
        <i class="la la-upload"></i>Upload
    </button>
</div>
