import { ApiHttpService } from "../api-http.service";
import * as i0 from "@angular/core";
import * as i1 from "../api-http.service";
var AuthService = /** @class */ (function () {
    function AuthService(http) {
        this.http = http;
    }
    AuthService.prototype.login = function (data) {
        return this.http.post('/auth/login', data);
    };
    AuthService.prototype.masqueradeLogin = function (id) {
        return this.http.post('/auth/masquerade_login', { user: id });
    };
    AuthService.ngInjectableDef = i0.defineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.inject(i1.ApiHttpService)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
