import { ApiHttpService } from "../api-http.service";
import * as i0 from "@angular/core";
import * as i1 from "../api-http.service";
var FTPProcessLogService = /** @class */ (function () {
    function FTPProcessLogService(http) {
        this.http = http;
        this.e = '/ftp-process-logs';
    }
    FTPProcessLogService.prototype.list = function (qp) {
        return this.http.get(this.e, qp);
    };
    FTPProcessLogService.ngInjectableDef = i0.defineInjectable({ factory: function FTPProcessLogService_Factory() { return new FTPProcessLogService(i0.inject(i1.ApiHttpService)); }, token: FTPProcessLogService, providedIn: "root" });
    return FTPProcessLogService;
}());
export { FTPProcessLogService };
