import * as _ from 'lodash';
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";
import * as i0 from "@angular/core";
import * as i1 from "ngx-toastr";
var UserService = /** @class */ (function () {
    function UserService(t) {
        this.t = t;
        this.detail = new BehaviorSubject(this.getDetailFromStorage());
    }
    Object.defineProperty(UserService.prototype, "isAnonymous", {
        get: function () {
            return _.isEmpty(this.detail.value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserService.prototype, "isAdminOrStaff", {
        get: function () {
            return !_.isEmpty(this.detail.value) && this.detail.value.is_staff_or_admin;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserService.prototype, "isAdmin", {
        get: function () {
            return !_.isEmpty(this.detail.value) && this.detail.value.is_superuser;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserService.prototype, "isStaff", {
        get: function () {
            return !_.isEmpty(this.detail.value) && this.detail.value.is_staff;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserService.prototype, "isOrganizationStaffOrAdmin", {
        get: function () {
            return !_.isEmpty(this.detail.value)
                && this.detail.value.is_staff_or_admin
                && !this.detail.value.is_system_staff_or_admin;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserService.prototype, "isOrganizationStaff", {
        get: function () {
            return !_.isEmpty(this.detail.value)
                && this.detail.value.is_staff
                && !this.detail.value.is_system_staff_or_admin;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserService.prototype, "isOrganizationAdmin", {
        get: function () {
            return !_.isEmpty(this.detail.value)
                && this.detail.value.is_superuser
                && !this.detail.value.is_system_staff_or_admin;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserService.prototype, "isSystemStaffOrAdmin", {
        get: function () {
            return !_.isEmpty(this.detail.value) && this.detail.value.is_system_staff_or_admin;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserService.prototype, "isSystemAdmin", {
        get: function () {
            return !_.isEmpty(this.detail.value)
                && this.detail.value.is_superuser
                && this.detail.value.organization == null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserService.prototype, "isMasquerading", {
        get: function () {
            return !_.isEmpty(_.get(this, 'detail.value.original'));
        },
        enumerable: true,
        configurable: true
    });
    UserService.prototype.setUser = function (user, forSessionOnly) {
        if (forSessionOnly === void 0) { forSessionOnly = true; }
        if (this.getDetailFromStorage() !== null)
            throw new Error('User is already set. Please call clear() and the set again.');
        this._setUser(user, forSessionOnly);
    };
    UserService.prototype.update = function (value) {
        var newVal = _.merge(this.getDetailFromStorage(), value);
        this._setUser(newVal, newVal['forSessionOnly']);
    };
    UserService.prototype.clear = function () {
        sessionStorage.removeItem('u');
        localStorage.removeItem('u');
        this.detail.next(this.getDetailFromStorage());
    };
    UserService.prototype.hasPerm = function (regexPermissionStr) {
        var idx = _.findIndex(this.detail.value.permissions, function (item) {
            var re = new RegExp(regexPermissionStr, 'ig');
            return re.test(item);
        });
        return idx > -1;
    };
    UserService.prototype.masquerade = function (user) {
        if (this.isMasquerading) {
            this.t.error('Your are already impersonating. Nested impersonation is not supported.');
            return;
        }
        user['original'] = this.detail.value;
        this._setUser(user, this.detail.value['forSessionOnly']);
    };
    UserService.prototype.exitMasquerade = function () {
        if (this.isMasquerading === true) {
            this._setUser(this.detail.value['original'], this.detail.value['original']['forSessionOnly']);
        }
    };
    UserService.prototype.getDetailFromStorage = function () {
        var u = sessionStorage.getItem('u');
        u = _.isEmpty(u) ? localStorage.getItem('u') : u;
        return _.isEmpty(u) ? null : JSON.parse(u);
    };
    UserService.prototype._setUser = function (user, forSessionOnly) {
        var storage = forSessionOnly === true ? sessionStorage : localStorage;
        user['forSessionOnly'] = forSessionOnly;
        storage.setItem('u', JSON.stringify(user));
        this.detail.next(user);
    };
    UserService.ngInjectableDef = i0.defineInjectable({ factory: function UserService_Factory() { return new UserService(i0.inject(i1.ToastrService)); }, token: UserService, providedIn: "root" });
    return UserService;
}());
export { UserService };
