import { Injector } from '@angular/core';
import { ApiSdkService } from "./core/api-sdk/api-sdk.service";
var AppStartupService = /** @class */ (function () {
    function AppStartupService(injector) {
        this.injector = injector;
    }
    Object.defineProperty(AppStartupService.prototype, "data", {
        get: function () {
            return this._data;
        },
        enumerable: true,
        configurable: true
    });
    AppStartupService.prototype.load = function () {
        var _this = this;
        this._data = null;
        return this.api.organization.list({ by_domain: true })
            .toPromise()
            .then(function (resp) { return _this._data = resp.body.length == 0 ? null : resp.body[0]; })
            .catch(function () { return Promise.resolve(); });
    };
    Object.defineProperty(AppStartupService.prototype, "api", {
        get: function () {
            return this.injector.get(ApiSdkService);
        },
        enumerable: true,
        configurable: true
    });
    return AppStartupService;
}());
export { AppStartupService };
