import { ApiHttpService } from "../api-http.service";
import { SubscriptionClass } from "./subscription.class";
import * as i0 from "@angular/core";
import * as i1 from "../api-http.service";
var NotificationService = /** @class */ (function () {
    function NotificationService(http) {
        this.http = http;
        this.e = '/notifications';
    }
    NotificationService.prototype.list = function (qp) {
        return this.http.get(this.e, qp);
    };
    Object.defineProperty(NotificationService.prototype, "subscription", {
        get: function () {
            return new SubscriptionClass(this.http);
        },
        enumerable: true,
        configurable: true
    });
    NotificationService.ngInjectableDef = i0.defineInjectable({ factory: function NotificationService_Factory() { return new NotificationService(i0.inject(i1.ApiHttpService)); }, token: NotificationService, providedIn: "root" });
    return NotificationService;
}());
export { NotificationService };
