import { Router } from '@angular/router';
import { ToastrService } from "ngx-toastr";
import { UserService } from "../core/user.service";
import * as i0 from "@angular/core";
import * as i1 from "../core/user.service";
import * as i2 from "@angular/router";
import * as i3 from "ngx-toastr";
var AdminGuard = /** @class */ (function () {
    function AdminGuard(user, router, t) {
        this.user = user;
        this.router = router;
        this.t = t;
    }
    AdminGuard.prototype.canActivate = function (next, state) {
        if (!this.user.isAdminOrStaff) {
            this.t.error('Page cannot be accessed. Please login with admin or staff credential');
            this.router.navigate(['/auth/login'], { queryParams: { next: encodeURIComponent(state.url) } });
            return false;
        }
        return true;
    };
    AdminGuard.ngInjectableDef = i0.defineInjectable({ factory: function AdminGuard_Factory() { return new AdminGuard(i0.inject(i1.UserService), i0.inject(i2.Router), i0.inject(i3.ToastrService)); }, token: AdminGuard, providedIn: "root" });
    return AdminGuard;
}());
export { AdminGuard };
