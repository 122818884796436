/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../node_modules/ng-block-ui/ng-block-ui.ngfactory";
import * as i3 from "ng-block-ui";
import * as i4 from "@angular/router";
import * as i5 from "./app.component";
import * as i6 from "./app-startup.service";
import * as i7 from "./core/logger.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "block-ui", [["name", "app"]], null, null, null, i2.View_BlockUIComponent_0, i2.RenderType_BlockUIComponent)), i1.ɵdid(1, 114688, null, 0, i3.BlockUIComponent, [i3.ɵc], { name: [0, "name"] }, null), (_l()(), i1.ɵeld(2, 16777216, null, 0, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(3, 212992, null, 0, i4.RouterOutlet, [i4.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var currVal_0 = "app"; _ck(_v, 1, 0, currVal_0); _ck(_v, 3, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 114688, null, 0, i5.AppComponent, [i6.AppStartupService, i4.Router, i7.LoggerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i5.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
