import { ApiHttpService } from "../api-http.service";
import * as i0 from "@angular/core";
import * as i1 from "../api-http.service";
var PermissionService = /** @class */ (function () {
    function PermissionService(http) {
        this.http = http;
        this.e = '/permissions';
    }
    PermissionService.prototype.list = function () {
        return this.http.get(this.e);
    };
    PermissionService.ngInjectableDef = i0.defineInjectable({ factory: function PermissionService_Factory() { return new PermissionService(i0.inject(i1.ApiHttpService)); }, token: PermissionService, providedIn: "root" });
    return PermissionService;
}());
export { PermissionService };
