import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from "@angular/router";
import { takeUntil } from "rxjs/operators";
import { ExComponent } from "../core/classes/ex-component";
import { ContentHeaderService } from "../core/content-header.service";
import { UserService } from "../core/user.service";
import { LeftMenuService } from "../shared/components/left-menu/left-menu.service";
var UserComponent = /** @class */ (function (_super) {
    tslib_1.__extends(UserComponent, _super);
    function UserComponent(leftMenu, contentHeader, u, router) {
        var _this = _super.call(this) || this;
        _this.leftMenu = leftMenu;
        _this.contentHeader = contentHeader;
        _this.u = u;
        _this.router = router;
        return _this;
    }
    UserComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.contentHeader.setBreadcrumbHome({ title: 'Home', route: '/u' });
        this.contentHeader.pageActionContainer = this._vcr;
        this.initLeftMenu();
        this.router.events
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(function (e) {
            if (e instanceof NavigationStart) {
                _this.ui.isRouteLoading = true;
            }
            else if (e instanceof NavigationCancel ||
                e instanceof NavigationError ||
                e instanceof NavigationEnd) {
                _this.ui.isRouteLoading = false;
            }
        });
    };
    UserComponent.prototype.initLeftMenu = function () {
        var children = [
            {
                title: 'All',
                route: '/u/probes',
                queryParamsHandling: 'merge',
                queryParams: { group: undefined }
            }
        ];
        this.u.detail.value.groups.forEach(function (group) {
            children.push({
                title: group.name,
                route: './probes',
                queryParamsHandling: 'merge',
                queryParams: { group: group.id }
            });
        });
        this.leftMenu.items.next([
            {
                title: 'Home',
                items: [
                    {
                        title: 'Probe Dashboard',
                        icon: 'mdi mdi-speedometer',
                        children: children
                    },
                    {
                        title: 'Notifications',
                        icon: 'mdi mdi-bell',
                        route: '/u/notifications',
                    },
                    {
                        title: 'Reports',
                        icon: 'mdi mdi-file-chart',
                        route: '/u/report',
                        isVisible: this.u.hasPerm('report.view_efficiencyreport')
                    },
                ],
            },
        ]);
    };
    return UserComponent;
}(ExComponent));
export { UserComponent };
