import { ApiHttpService } from "../api-http.service";
import * as i0 from "@angular/core";
import * as i1 from "../api-http.service";
var CloudFilesService = /** @class */ (function () {
    function CloudFilesService(http) {
        this.http = http;
        this.e = '/cloudfiles';
    }
    CloudFilesService.prototype.remove = function (id) {
        return this.http.remove(this.e + "/" + id);
    };
    CloudFilesService.ngInjectableDef = i0.defineInjectable({ factory: function CloudFilesService_Factory() { return new CloudFilesService(i0.inject(i1.ApiHttpService)); }, token: CloudFilesService, providedIn: "root" });
    return CloudFilesService;
}());
export { CloudFilesService };
