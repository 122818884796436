import * as i0 from "@angular/core";
var LoggerService = /** @class */ (function () {
    function LoggerService() {
    }
    LoggerService.prototype.info = function (value) {
        var rest = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            rest[_i - 1] = arguments[_i];
        }
        console.info.apply(console, [value].concat(rest));
    };
    LoggerService.prototype.debug = function (value) {
        var rest = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            rest[_i - 1] = arguments[_i];
        }
        console.debug.apply(console, [value].concat(rest));
    };
    LoggerService.prototype.error = function (value) {
        var rest = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            rest[_i - 1] = arguments[_i];
        }
        console.error.apply(console, [value].concat(rest));
    };
    LoggerService.prototype.warn = function (value) {
        var rest = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            rest[_i - 1] = arguments[_i];
        }
        console.warn.apply(console, [value].concat(rest));
    };
    LoggerService.ngInjectableDef = i0.defineInjectable({ factory: function LoggerService_Factory() { return new LoggerService(); }, token: LoggerService, providedIn: "root" });
    return LoggerService;
}());
export { LoggerService };
