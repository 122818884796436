import {AgmCoreModule} from "@agm/core";
import {registerLocaleData} from "@angular/common";
import {HttpClientModule} from "@angular/common/http";
import localeFr from '@angular/common/locales/fr';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ConfirmationPopoverModule} from "angular-confirmation-popover";
import {LaddaModule} from "angular2-ladda";
import {BlockUIModule} from "ng-block-ui";

import {Daterangepicker} from 'ng2-daterangepicker';
import {
    BsDatepickerModule,
    BsDropdownModule,
    PaginationModule,
    ProgressbarModule,
    TooltipModule
} from "ngx-bootstrap";
import {NgxMaskModule} from "ngx-mask";

import {ToastrModule} from "ngx-toastr";
import {environment} from "../environments/environment";
import {AdminModule} from "./admin/admin.module";
import {AppRoutingModule} from "./app-routing.module";
import {AppStartupService} from "./app-startup.service";

import {AppComponent} from './app.component';
import {AuthModule} from "./auth/auth.module";
import {CoreModule} from "./core/core.module";
import {UserModule} from "./user/user.module";

// Patching data for the use of currency pipe to put the symbol at the back.
// At a few places in the app, for the data formatting, I used currency for the ease to format
// Non-currency number but has unit like `0.00"` or `0.00%`.
localeFr[13][0] = '.'
localeFr[14][2] = '#,##0.00¤'
registerLocaleData(localeFr);

export function startupServiceFactory(startupService: AppStartupService): Function {
    return () => startupService.load();
}


@NgModule({
    declarations: [
        AppComponent
    ],
    imports:      [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,

        ToastrModule.forRoot({
            progressBar:       true,
            progressAnimation: "decreasing"
        }),
        LaddaModule.forRoot({
            style:        "zoom-in",
            spinnerColor: "white",
            spinnerLines: 12
        }),
        Daterangepicker,
        BsDropdownModule.forRoot(),
        BsDatepickerModule.forRoot(),
        PaginationModule.forRoot(),
        TooltipModule.forRoot(),
        ProgressbarModule.forRoot(),
        BlockUIModule.forRoot(),
        ConfirmationPopoverModule.forRoot({
            confirmButtonType: 'danger btn-sm',
            cancelButtonType:  'default btn-sm'
        }),
        BsDatepickerModule.forRoot(),
        NgxMaskModule.forRoot(),
        AgmCoreModule.forRoot({
            apiKey: environment.googleMapKey
        }),

        CoreModule.forRoot(environment.apiUrl, 60),
        AuthModule,
        AdminModule,
        UserModule,

        AppRoutingModule
    ],
    providers:    [
        AppStartupService,
        {
            provide:    APP_INITIALIZER,
            useFactory: startupServiceFactory,
            deps:       [AppStartupService],
            multi:      true
        }
    ],
    exports:      [BrowserAnimationsModule],
    bootstrap:    [AppComponent]
})
export class AppModule {
}
