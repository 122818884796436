import { ApiHttpService } from "../api-http.service";
import * as i0 from "@angular/core";
import * as i1 from "../api-http.service";
var SensorsLogCommentService = /** @class */ (function () {
    function SensorsLogCommentService(http) {
        this.http = http;
        this.e = '/probes';
    }
    SensorsLogCommentService.prototype.list = function (probeId, sensorLogId, qp) {
        return this.http.get(this.e + "/" + probeId + "/sensors_logs/" + sensorLogId + "/comments", qp);
    };
    SensorsLogCommentService.prototype.save = function (probeId, sensorLogId, data) {
        return this.http.save(this.e + "/" + probeId + "/sensors_logs/" + sensorLogId + "/comments/{id}", data);
    };
    SensorsLogCommentService.prototype.remove = function (probeId, sensorLogId, id) {
        return this.http.remove(this.e + "/" + probeId + "/sensors_logs/" + sensorLogId + "/comments/" + id);
    };
    SensorsLogCommentService.ngInjectableDef = i0.defineInjectable({ factory: function SensorsLogCommentService_Factory() { return new SensorsLogCommentService(i0.inject(i1.ApiHttpService)); }, token: SensorsLogCommentService, providedIn: "root" });
    return SensorsLogCommentService;
}());
export { SensorsLogCommentService };
