import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { EventEmitter } from '@angular/core';
import * as _ from 'lodash';
import { TimeoutError } from "rxjs/internal-compatibility";
import { throwError } from "rxjs/internal/observable/throwError";
import { catchError, map, timeout } from "rxjs/operators";
import { UserService } from "../user.service";
import Utils from "../utils";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../user.service";
var ApiHttpService = /** @class */ (function () {
    function ApiHttpService(config, http, u) {
        this.config = config;
        this.http = http;
        this.u = u;
    }
    ApiHttpService.prototype.get = function (endpoint, qs) {
        if (qs === void 0) { qs = {}; }
        return this.request(endpoint, 'GET', qs);
    };
    ApiHttpService.prototype.post = function (endpoint, data, qs) {
        if (qs === void 0) { qs = {}; }
        return this.request(endpoint, 'POST', qs, data);
    };
    ApiHttpService.prototype.patch = function (endpoint, data, qs) {
        if (qs === void 0) { qs = {}; }
        return this.request(endpoint, 'PATCH', qs, data);
    };
    ApiHttpService.prototype.put = function (endpoint, data, qs) {
        if (qs === void 0) { qs = {}; }
        return this.request(endpoint, 'PUT', qs, data);
    };
    ApiHttpService.prototype.remove = function (endpoint, qs) {
        if (qs === void 0) { qs = {}; }
        return this.request(endpoint, 'DELETE', qs);
    };
    ApiHttpService.prototype.download = function (endpoint, method, qs, data) {
        var _this = this;
        if (method === void 0) { method = 'GET'; }
        if (qs === void 0) { qs = {}; }
        var a = document.createElement('a');
        var event = new EventEmitter();
        var requestOpts = {
            params: _.omitBy(qs, _.isNil),
            body: data,
            headers: this.getHeader(),
            responseType: 'blob',
            observe: 'response'
        };
        this.http.request(method, this.getUrl(endpoint), requestOpts)
            .pipe(timeout(this.requestTimeout), catchError(this.handleBlobError))
            .subscribe(function (resp) {
            var filename = _this.nameFromContentDisposition(resp.headers.get('content-disposition'));
            var blob = new Blob([resp['body']], { type: resp.headers.get("content-type") });
            a.download = qs._downloadFileName || filename || undefined;
            a.href = window.URL.createObjectURL(blob);
            a.click();
            event.emit();
        }, function (e) { return event.error(e); }, function () { return event.complete(); });
        return event.asObservable();
    };
    ApiHttpService.prototype.save = function (endpoint, data, qs) {
        if (qs === void 0) { qs = {}; }
        var e = Utils.format(endpoint, data).replace(/\/$/g, '');
        if (data['id'])
            return this.patch(e, data, qs);
        else
            return this.post(e, data, qs);
    };
    ApiHttpService.prototype.getUrl = function (endpoint) {
        return (this.config.apiUrl + endpoint);
    };
    ApiHttpService.prototype.getHeader = function () {
        var headers = { 'Content-Type': 'application/json' }, apiKey = this.u.detail.getValue() ? this.u.detail.getValue().token : null;
        if (apiKey)
            headers['Authorization'] = "Token " + apiKey;
        return headers;
    };
    Object.defineProperty(ApiHttpService.prototype, "requestTimeout", {
        get: function () {
            return this.config.requestTimeout * 1000;
        },
        enumerable: true,
        configurable: true
    });
    ApiHttpService.prototype.request = function (endpoint, method, qs, data) {
        var _this = this;
        if (qs === void 0) { qs = {}; }
        var requestOpts = {
            params: _.omitBy(qs, _.isNil),
            body: data,
            headers: this.getHeader(),
            responseType: 'json',
            observe: 'response'
        };
        var pageSize = qs.page_size || 10, page = qs.page || 1;
        return this.http.request(method, this.getUrl(endpoint), requestOpts)
            .pipe(timeout(this.requestTimeout), catchError(this.handleError), map(function (resp) {
            try {
                resp['meta'] = resp['body']['meta'];
                resp['body'] = resp['body']['data'];
                var cnt = _this.itemDisplayCnt(page, pageSize, resp);
                resp['meta']['from'] = cnt.from;
                resp['meta']['to'] = cnt.to;
            }
            catch (e) {
            }
            return resp;
        }));
    };
    ApiHttpService.prototype.handleError = function (e) {
        var newErrorResp = e;
        if (e.error instanceof ErrorEvent || e.error instanceof ProgressEvent) {
            newErrorResp = new HttpErrorResponse({
                error: {
                    error: {
                        detail: 'Problem connecting to the server.',
                        type: 'ClientError'
                    }
                },
                headers: e.headers,
                status: e.status,
                statusText: e.statusText,
                url: e.url
            });
        }
        else if (e instanceof TimeoutError) {
            newErrorResp = new HttpErrorResponse({
                error: {
                    error: {
                        detail: 'Operation timed out',
                        type: 'ServerError'
                    }
                },
                headers: null,
                status: 504,
                statusText: 'Gateway Timeout',
                url: null,
            });
        }
        return throwError(newErrorResp);
    };
    ApiHttpService.prototype.itemDisplayCnt = function (page, pageSize, resp) {
        var from = ((page - 1) * pageSize) + 1, to = from + resp['body'].length - 1;
        return { from: from, to: to };
    };
    ApiHttpService.prototype.handleBlobError = function (err) {
        if (err instanceof HttpErrorResponse
            && err.error instanceof Blob
            && err.error.type === "application/json") {
            // https://github.com/angular/angular/issues/19888
            // When request of type Blob, the error is also in Blob instead of object of json data
            return new Promise(function (resolve, reject) {
                var reader = new FileReader();
                reader.onload = function (e) {
                    try {
                        var errmsg = JSON.parse(e.target.result);
                        reject(new HttpErrorResponse({
                            error: errmsg,
                            headers: err.headers,
                            status: err.status,
                            statusText: err.statusText,
                            url: err.url
                        }));
                    }
                    catch (e) {
                        console.log(e);
                        // TODO: Throw some informatic error to user
                        reject(err);
                    }
                };
                reader.onerror = function (e) {
                    console.log(e);
                    // TODO: Throw some informatic error to user
                    reject(err);
                };
                reader.readAsText(err.error);
            });
        }
        return this.handleError(err);
    };
    ApiHttpService.prototype.nameFromContentDisposition = function (contentDisposition) {
        return _.get(RegExp(/filename=(.*)/g).exec(contentDisposition), '1');
    };
    ApiHttpService.ngInjectableDef = i0.defineInjectable({ factory: function ApiHttpService_Factory() { return new ApiHttpService(i0.inject("config"), i0.inject(i1.HttpClient), i0.inject(i2.UserService)); }, token: ApiHttpService, providedIn: "root" });
    return ApiHttpService;
}());
export { ApiHttpService };
