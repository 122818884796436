<div id="wrapper" style="padding-bottom: 60px">

    <!-- Navigation Bar-->
    <app-header></app-header>
    <!-- End Navigation Bar-->

    <!-- ========== Left Sidebar Start ========== -->
    <app-left-menu></app-left-menu>
    <!-- Left Sidebar End -->


    <!-- Page Content Start -->
    <div class="content-page">
        <div class="content">
            <div class="container-fluid">

                <!-- Page title box -->
                <div class="page-title-box">
                    <ol class="breadcrumb float-right">
                        <li *ngFor="let item of contentHeader.breadcrumb" class="breadcrumb-item">
                            <a [routerLink]="item.route"
                               [queryParams]="item.queryParams">
                                {{item.title}}
                            </a>
                        </li>
                        <li class="breadcrumb-item active">{{contentHeader.pageTitle}}</li>
                    </ol>

                    <div class="d-flex">
                        <h4 class="page-title">
                            <ng-container *ngIf="contentHeader.isPageTitleHidden==false">
                                {{contentHeader.pageTitle}}
                            </ng-container>
                            &nbsp;
                        </h4>

                        <div class="float-left">
                            <ng-container #pageActionContainer></ng-container>
                        </div>
                    </div>
                </div>
                <!-- End page title box -->

                <router-outlet></router-outlet>

                <div *ngIf="ui.isRouteLoading" class="content-loader text-center">
                    <div class="sk-folding-cube">
                        <div class="sk-cube1 sk-cube"></div>
                        <div class="sk-cube2 sk-cube"></div>
                        <div class="sk-cube4 sk-cube"></div>
                        <div class="sk-cube3 sk-cube"></div>
                    </div>
                </div>
            </div> <!-- end container-fluid-->
        </div> <!-- end contant-->
    </div>
    <!-- End Page Content-->

    <!-- Footer -->
    <!-- FOR NOW, WE DONT WANT TO HAVE FOOTER -->
    <!-- End Footer -->
</div>
