import { pluck } from "rxjs/operators";
import { ApiHttpService } from "../api-http.service";
import * as i0 from "@angular/core";
import * as i1 from "../api-http.service";
var ProbeService = /** @class */ (function () {
    function ProbeService(http) {
        this.http = http;
        this.e = '/probes';
    }
    ProbeService.prototype.list = function (qp) {
        return this.http.get(this.e, qp);
    };
    ProbeService.prototype.save = function (data) {
        return this.http.save(this.e + '/{id}', data);
    };
    ProbeService.prototype.remove = function (id) {
        return this.http.remove(this.e + ("/" + id));
    };
    ProbeService.prototype.get = function (id, qp) {
        return this.http.get(this.e + ("/" + id), qp);
    };
    ProbeService.prototype.sensorsLog = function (probeId, qp) {
        return this.http.get(this.e + "/" + probeId + "/sensors_logs", qp);
    };
    ProbeService.prototype.listTags = function (term) {
        return this.http.get(this.e + "/tags", { term: term }).pipe(pluck('body'));
    };
    ProbeService.prototype.downloadCSV = function (id, qs) {
        if (qs === void 0) { qs = {}; }
        return this.http.download(this.e + "/" + id + "/sensors_logs/actions/download", 'GET', qs);
    };
    ProbeService.ngInjectableDef = i0.defineInjectable({ factory: function ProbeService_Factory() { return new ProbeService(i0.inject(i1.ApiHttpService)); }, token: ProbeService, providedIn: "root" });
    return ProbeService;
}());
export { ProbeService };
