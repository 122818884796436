import { ElementRef, EventEmitter, OnInit } from '@angular/core';
import * as _ from "lodash";
import { BsModalRef } from "ngx-bootstrap";
import { ToastrService } from "ngx-toastr";
import { UploadStatus } from "ngx-uploader";
import { ApiSdkService } from "../../../core/api-sdk/api-sdk.service";
import { LoggerService } from "../../../core/logger.service";
var FileUploaderModalComponent = /** @class */ (function () {
    function FileUploaderModalComponent(bsModalRef, l, api, t) {
        this.bsModalRef = bsModalRef;
        this.l = l;
        this.api = api;
        this.t = t;
    }
    Object.defineProperty(FileUploaderModalComponent.prototype, "files", {
        get: function () {
            return this._files;
        },
        enumerable: true,
        configurable: true
    });
    FileUploaderModalComponent.prototype.ngOnInit = function () {
        this.options = { concurrency: 1 };
        this.uploadInput = new EventEmitter();
        this.uploadOutput = new EventEmitter();
        this._files = [];
        this.ui = { laddaUpload: false };
    };
    FileUploaderModalComponent.prototype.onUploadOutput = function (e) {
        this.l.debug('UploadOutput', e);
        this.uploadOutput.emit(e);
        switch (e.type) {
            case 'addedToQueue':
                this._files = [];
                this._files.push(e);
                break;
            case 'uploading':
                this.ui.laddaUpload = true;
                break;
            case 'done':
                this.ui.laddaUpload = false;
                if (e.file.responseStatus == 201) {
                    this._files = [];
                    this.uploadInput.emit({ type: 'removeAll' });
                    this.fileUploader.nativeElement.value = '';
                    this.name = '';
                    this.t.success('File has been successfully.');
                    this.response.emit({ type: 'success', response: e.file.response });
                }
                else {
                    this.t.error(e.file.response.error.detail);
                    this.files[0].file.progress.status = UploadStatus.Queue;
                    this.response.emit({ type: 'error', response: e.file.response });
                }
                break;
            default:
                break;
        }
    };
    FileUploaderModalComponent.prototype.startUpload = function () {
        if (this.files.length == 0)
            return;
        var header = this.api.http.getHeader(), data = _.omitBy({
            target: this.target,
            object_id: this.objectId,
            name: this.name
        }, _.isNil);
        delete header['Content-Type'];
        var event = {
            type: 'uploadAll',
            url: this.api.http.getUrl('/cloudfiles'),
            method: 'POST',
            data: data,
            headers: header
        };
        this.uploadInput.emit(event);
    };
    return FileUploaderModalComponent;
}());
export { FileUploaderModalComponent };
